.userInputType-wrapper {
  padding-bottom: 20px;

  label {
    display: block;
  }

  input,
  select,
  textarea {
    display: inline-block;
    height: 24px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: $color-text-13;
    background-color: $color-background-1;
    background-image: none;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 2px 1px $color-box-shadow-1;
    box-shadow: inset 0 -2px 3px $color-box-shadow-1;
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    border-color: rgba(0, 0, 0, 0.23);
    border-right: 1px solid $color-border-1;

    &.input-error {
      border-color: $color-border-7;
    }
  }

  .input-hover-notify-wrapper {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
  }

  .input-smaller-text {
    padding: 3px 0 0;
    font-size: 85%;
  }

  .input-error-notify-message {
    color: $color-text-10;
    text-align: right;
  }

  .input-subMsg-wrapper {
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
  }

  input,
  select,
  textarea,
  .input-width {
    width: calc(100% - 40px);
  }

  @media (min-width: 660px) {
    input,
    select,
    textarea,
    .input-width {
      width: 620px;
    }
  }
}

.userInputType-select-input-wrapper {
  width: calc(100% - 40px);

  @media (min-width: 660px) {
    width: 620px;
  }
}
