.header-wrapper {
  background-color: $basics-colors-primary-black;
  border-color: $color-border-2;
  margin-bottom: 0;
  box-shadow: 0 6px 6px -4px $color-box-shadow-1 inset;

  .header-content-wrapper {
    padding: 13px 15px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    span {
      @include vertical-align();

      img {
        margin-right: 15px
      }

      h1 {
        display: inline-block;
        padding: 0;
        margin: -3px 0 0;
        font-size: 1.5em;
        color: $basics-colors-primary-charcoal-300;
        font-weight: 500;
      }

      &.login-wrapper {
        color: $basics-colors-primary-white;
        span {
          margin-right: 5px;

          &.header-link-text {
            margin-top: 5px;

            a {
              color: $basics-colors-primary-white;

              &:focus {
                outline: 2px solid #5f60ff;
              }
            }

            .user-name {
              color: $basics-colors-primary-white;
              max-width: 100px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: inline-block!important;
              user-select: none;
            }
          }

          &.user-logo img {
            max-width: 25px;
            margin-right: 5px;
            border-radius: 50%;
          }

          &.user-logo.user-profile-image-default img {
            max-width: 25px;
            margin-right: 5px;
            // set SVG to white.
            // reference: https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element
            filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(277deg) brightness(109%) contrast(101%);
          }

          &.separator {
            margin: 0 15px;
            border-right: $color-seperator-1 solid 1px;
            height: 100%;
          }

          
        }

        #header-link-switchCompany {
          min-width: 112px;
        }
        
        #header-link-logout {
          color: $basics-colors-primary-white;
          min-width: 50px;

          &:focus {
            outline: 2px solid #5f60ff;
          }
        }
      }
    }
  }
}
