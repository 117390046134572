.swagger-page-wrapper {
  // ensures that swagger ui response scroll does not trigger some scrolling error
  *{
    touch-action: none;
  } 

  .company-info-wrapper {
    margin: 40px 0 20px;
  }

  .select-app-wrapper, .api-headers-wrapper, .instructions-wrapper {
    width: 100%;
    max-width: 1460px;
    margin: 40px auto 0;
    padding: 0 20px;
  }

  .instructions-wrapper {
    margin-bottom: 40px;
    .description-wrapper {
      margin-bottom: 40px;
      .description-title {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .instructions-description {
        .warning-notice {
          font-weight: bold;
          color: $color-text-10;
        }
      }
    }    
    .instructions-title {
      margin: 20px 0 0;
      font-weight: bold;
    }
    .instructions-content-wrapper {
      margin-top: 10px;
      .instruction-list {
        margin-top: 5px;
        line-height: 1.8em;
      }
    }

    .pdf-links-wrapper {
      list-style-image: url('../images/pdf.png');
      padding-left: 25px;

      a {
        &:focus {
          outline: 2px solid #5f60ff;
        }
      }
    }
  }

  .api-headers-wrapper {
    .userInputType-wrapper:last-child {
      padding-bottom: 0;
    }
  }

  .swagger-wrapper {
    padding: 0 0 50px;
    margin-top: -20px;
    .swagger-ui {
      .information-container {
        hgroup.main {
          display: none;
        }
      }
      .scheme-container {
        box-shadow: none;
        margin: 0;

        .servers {
          margin-top: 10px;
        }

        .auth-wrapper {
          display: none;
        }
      }
      .swagger-ui table tbody tr td:first-of-type {
        max-width: 30%;
      }
      .parameters-col_description {
        width: 70%;
      }
      .download-contents {
        width: 85px;
      }
    }
  }
}
